import axios from "axios";
import route from "../router/index";
import { ElMessageBox, ElMessage } from 'element-plus'
axios.defaults.responseType = "json";
axios.defaults.headers.post["Content-Type"] = "application/json;charset=UTF-8";
axios.defaults.headers = {
    "X-Requested-With": "XMLHttpRequest"
};
const openAPI = "/openapispring";

// 添加响应拦截器
axios.interceptors.response.use((response) => {
    return response;
}, (error) => {
    //401 跳回登录页面
    if (error.response.status == 401) {
        ElMessageBox.alert('登录信息超时,请重新登录', '登录超时', {
            confirmButtonText: '跳转登录页面',
            callback: (action) => {
                route.push({
                    path: '/login'
                })
                ElMessage({
                    type: 'success',
                    message: '返回成功',
                })
            },
        })

    }
    // 对响应错误做点什么
    return Promise.reject(error);
});


//获取菜单
const getMenuList = () => {
    return axios.get(openAPI + `/menu/findall`)
}

//获取热门问题
const getHotPro = (data) => {
    return axios.get(`${openAPI}/faq/article/list`, { params: data })
}

//复合查询
const getArticle = (data) => {
    return axios.get(`${openAPI}/faq/article/list`, { params: data })
}

//根据title获取内容
const getArticleContent = (data) => {
    return axios.get(`${openAPI}/faq/article`, { params: data })
}

//上传图片
const uploadPictures = (parmas) => {
    return axios.post(`${openAPI}/faq/picture/upload`, parmas)
}

//保存内容
const saveContent = (params) => {
    return axios.post(`${openAPI}/faq/article/save`, params)
}

const deleteArticle = (data) => {
    return axios.delete(`${openAPI}/faq/delete`,{params: data})
}

//根据父级菜单查询子菜单
const querySubmenu = (data) => {
    return axios.get(`${openAPI}/menu/findbyfatherid`, { params: data })
}

//登录
const login = (params) => {
    return axios.post(`${openAPI}/user/login`, params)
}

//删除菜单
const delMenu = (params) => {
    return axios.post(`${openAPI}/menu/deletebyid`, params)
}

//新增修改菜单
const newModifyMenu = (params) => {
    return axios.post(`${openAPI}/menu/save`, params)
}

//获取反馈
const getFeed = (params) => {
    return axios.get(`${openAPI}/question/findall`, { params: params })
}

//提交反馈
const submitFeed = (params) => {
    return axios.post(`${openAPI}/question/new`, params)
}

export {
    getArticle,
    saveContent,
    getMenuList,
    getArticleContent,
    getHotPro,
    uploadPictures,
    deleteArticle,
    querySubmenu,
    login,
    delMenu,
    newModifyMenu,
    submitFeed,
    getFeed
}

